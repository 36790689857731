<template>
  <div class="tw-flex tw-flex-col tw-items-center">
    <section>
      <div class="tw-p-12">
        <div class="tw-font-bold tw-text-4xl">
          <h1>About Us</h1>
        </div>
      </div>
    </section>
    <section class="">
      <div class="tw-container tw-relative tw-mx-auto">
        <!-- <div class="tw-w-screen tw-overflow-hidden"> -->
          <v-img :aspect-ratio="16/9" max-height="426" class="tw-object-cover tw-w-screen" src="https://firebasestorage.googleapis.com/v0/b/artisanhubng.appspot.com/o/adams.JPG?alt=media&token=e103dd17-dc12-49bd-8bdd-d20471e8f6bb"></v-img>
        <!-- </div> -->
        <div class="tw-grid tw-grid-cols-12 tw-relative">
          <div class="tw-col-span-12 tw-col-start-1 lg:tw-col-span-8 lg:tw-col-start-3">
            <div class="tw-bg-white tw-mt-0 lg:tw--mt-24 tw-px-4 lg:tw-px-24 tw-pt-4 lg:tw-pt-16 tw-pb-4 tw-w-full">
              <div>
                <h6 class="tw-font-bold tw-text-2xl lg:tw-text-4xl tw-mb-8">Welcome to Adam's Pages</h6>
                <!-- <p>
                  “Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model search for eolved over sometimes by accident, sometimes on purpose ”
                </p> -->
              </div>
              <div>
                <h6 class="tw-font-bold tw-text-lg lg:tw-text-xl tw-mb-2">
                  What we really do?
                </h6>
                <p>Adam's Pages is committed to offering a wide variety of new, bargain, gently read books, educational aid, stationary and gift items for customers of all ages.</p>
              </div>

            </div>
            <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-2 tw-px-4 lg:tw-px-24">
              <div>
                <h6 class="tw-mb-2 tw-font-bold tw-text-lg lg:tw-text-xl">Our Vision</h6>
                <p>To build a healthy reading culture in our community. To be the best destination for all readers, by connecting people with books they love, and an environment where they can discuss and interact with bibliophiles of like minds.</p>
              </div>
              <!-- <div>
                <h6 class="tw-mb-2 tw-font-bold tw-text-xl">Our Vision</h6>
                <p>Pellentesque sodales augue eget ultricies ultricies. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur sagittis ultrices condimentum.</p>
              </div> -->

            </div>
          </div>

        </div>
      </div>
    </section>
    <section>
      <div class="tw-w-screen tw-mt-12">
        <v-carousel hide-delimiter-background>
          <v-carousel-item v-for="i in 6" :key="i" src="https://firebasestorage.googleapis.com/v0/b/artisanhubng.appspot.com/o/adams_2.JPG?alt=media&token=031ac9df-e926-483e-855e-9d70a7b53c1c">
            <!-- <div>
              <v-img contain :aspect-ratio="16/9" class="tw-object-contain tw-h-full" src="https://firebasestorage.googleapis.com/v0/b/artisanhubng.appspot.com/o/adams_2.JPG?alt=media&token=031ac9df-e926-483e-855e-9d70a7b53c1c"></v-img>
            </div> -->

          </v-carousel-item>
            

        </v-carousel>

      </div>
        
    </section>
      
      
  </div>
</template>

<script>

export default {
  data() {
    return {
      settings: {
        "dots": true,
        "infinite": false,
        "arrows": true,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 2,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
      }
    }
  }
  
}
</script>

<style>
button.slick-prev:before, button.slick-next:before {
  color: #5D1049 !important;
}
</style>
